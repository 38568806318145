<template>
  <v-container class="fill-height">
    <v-row v-if="errorMsg.length != 0">
      <v-col>
        <v-alert border="left" type="error" elevation="2">
          {{ errorMsg }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="10" class="mx-auto" width="800">
          <v-app-bar color="blue" flat dark>
            <v-toolbar-title>
              <v-icon>mdi-account-details</v-icon>Please fill in the details
              below
            </v-toolbar-title>
          </v-app-bar>

          <v-container>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="name"
                :counter="100"
                :rules="nameRules"
                label="* Full Name/နာမည် - နောက်ဆုံးနာမည်/ឈ្មោះ - នាមត្រកូល/ຊື່ - ນາມສະກຸນ"
                required
              ></v-text-field>

              <v-text-field
                v-model="cid"
                :counter="13"
                :rules="cidRules"
                maxlength="13"
                label="* Reference ID/លេខសម្គាល់យោង/ကိုးကားစရာအိုင်ဒီ/ໝາຍເລກອ້າງອີງ "
                hint="ex. (0011241251122)"
                required
              ></v-text-field>

              <v-select
                :items="['Myanmar', 'Cambodia', 'Laos']"
                v-model="national"
                label="* Nationality/សញ្ជាតិ/နိုင်ငံသား/ສັນຊາດ"
                :rules="nameRules"
              ></v-select>

              <v-text-field
                v-model="address"
                :counter="250"
                :rules="addressRules"
                maxlength="250"
                label="* Address/អាសយដ្ឋាន/လိပ်စာ/ທີ່ຢູ່"
                hint
                required
              ></v-text-field>

              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                hint="ex.(0861234567)"
                :label="mobilePhoneLabel"
                maxlength="10"
                required
              >
                <template v-slot:append-outer>
                  <v-btn @click="requestOTP" :disabled="reqBtn"
                    >{{ reqBtnLabel }}
                  </v-btn>
                </template>
              </v-text-field>

              <v-text-field v-model="otp" label="OTP" required> </v-text-field>

              <!-- <v-checkbox
                label="Queue on Sunday 4 April (09.00-12.00)"
                v-model="onSunday3"
              ></v-checkbox> -->

              <!-- Can Choose Date -->
              <!-- date -->

              <!-- <v-menu
                v-model="datepicker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Choose the date"
                    v-model="queueDate"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="queueDate"
                  :allowed-dates="getAllowedDates"
                  min="2021-03-30"
                  max="2021-04-16"
                  @input="datepicker = false"
                ></v-date-picker>
              </v-menu>
              <v-select
                v-model="queueTime"
                :items="timeRanges"
                label="Choose Time"
                :rules="[v => !!v || 'please select the time.']"
                required
              >
              </v-select> -->

              <!-- <v-text-field v-model="email" :rules="emailRules" label="E-mail" ></v-text-field> -->
            </v-form>
          </v-container>
          <v-card-actions>
            <v-btn
              :disabled="!valid"
              color="success"
              class="mr-4"
              :loading="loading"
              @click="saveData"
            >
              <v-icon>mdi-content-save</v-icon>Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getToken, reserve, reqOtp } from "./req";
export default {
  data() {
    return {
      valid: false,
      datepicker: false,
      name: "",
      nameRules: [
        v => !!v || "This field is required.",
        v => /[A-Za-zก-๙]+/.test(v) || "fill in with English or Thai only."
      ],
      cid: "",
      cidRules: [
        v => !!v || "This field is required.",
        v => /\d{13}/.test(v) || "only numeric and fill up to 13 digits."
      ],
      phone: "",
      phoneRules: [
        v => !!v || "This field is required.",
        v =>
          /\d{10}/.test(v) ||
          "only numeric (no space or - or _) ex. 0812345678."
      ],
      email: "",
      emailRules: [
        // v => !!v || "E-mail is required",
        v => (v && /.+@.+\..+/.test(v)) || "input valid email."
      ],
      national: "",
      address: "",
      addressRules: [v => !!v || "This field is required."],
      foreignworker: "",
      dataSaved: false,
      onSunday27: false,
      onSunday3: false,
      onExtraTime: false,
      allowedDates: [
        "2021-03-30",
        "2021-03-31",
        "2021-04-01",
        "2021-04-02",
        "2021-04-04",
        "2021-04-05",
        "2021-04-06",
        "2021-04-07",
        "2021-04-08",
        "2021-04-09",
        "2021-04-11",
        "2021-04-12",
        "2021-04-13",
        "2021-04-14",
        "2021-04-15",
        "2021-04-16"
      ],
      timeRanges: [
        { value: "09:00:00", text: "09.00-12:00" },
        { value: "13:00:00", text: "13.00-16:00" },
        { value: "16:00:00", text: "16.00-18:00" }
      ],
      queueDate: this.$moment()
        .add(1, "d")
        .format("YYYY-MM-DD"),
      queueTime: "09:00:00",
      errorMsg: "",
      token: "",
      loading: false,
      mobilePhoneLabel:
        "*Mobile Number/លេខ​ទូរ​សព្ទ​ចល័ត/လက်ကိုင်ဖုန်းနာပတ်/ເບີ​ໂທລະ​ສັບ",
      uniqId: "",
      otp: "",
      reqBtn: false,
      reqBtnLabel: "REQUEST OTP"
    };
  },
  methods: {
    async saveData() {
      // check for valid otp requested
      if (this.otp.length == 0 || this.uniqId.length == 0) {
        return;
      }

      if (this.$refs.form.validate()) {
        this.loading = true;
        var tk = await getToken();
        var data = {
          name: this.name,
          refId: this.cid,
          address: this.address,
          national: this.national,
          phone: this.phone,
          uniqId: this.uniqId,
          queueDate: this.queueDate,
          queueTime: this.queueTime,
          otp: this.otp
        };
        if (tk != "") {
          reserve(tk, data)
            .then(res => {
              // console.log(res.data);
              this.$router.push({
                path: "/queue_result",
                query: { id: res.data.queueId }
              });
            })
            .catch(err => {
              console.log(err);
              this.errorMsg = "Invalid OTP";
            })
            .finally(() => (this.loading = false));
        }
      }
    },
    // allow
    getAllowedDates(v) {
      return this.allowedDates.includes(v);
    },
    async requestOTP() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        var tk = await getToken();
        var data = {
          cid: this.cid,
          phone: this.phone
        };
        if (tk != "" && data.cid != "" && data.phone != "") {
          reqOtp(tk, data)
            .then(res => {
              // console.log(res.data);
              if (res.data.status) {
                this.uniqId = res.data.uniqId;
                this.reqBtn = true;
                this.reqBtnLabel = "SENT";
              }
            })
            .catch(() => {
              this.reqBtn = false;
              this.reqBtnLabel = "REQUEST OTP";
            })
            .finally(() => (this.loading = false));
        }
      }
    }
  },
  watch: {
    onSunday27(v) {
      if (v) {
        this.onExtraTime = false;
        this.onSunday3 = false;
      }
    },
    onSunday3(v) {
      if (v) {
        this.onSunday27 = false;
        this.onExtraTime = false;
      }
    },
    onExtraTime(v) {
      if (v) {
        this.onSunday27 = false;
        this.onSunday3 = false;
      }
    },
    queueDate(v) {
      const d = [
        "2021-04-04",
        "2021-04-06",
        "2021-04-11",
        "2021-04-12",
        "2021-04-13",
        "2021-04-14",
        "2021-04-15"
      ];
      if (d.includes(v)) {
        this.timeRanges = [{ value: "09:00:00", text: "09.00-12:00" }];
        this.queueTime = "09:00:00";
      } else {
        this.timeRanges = [
          { value: "09:00:00", text: "09.00-12:00" },
          { value: "13:00:00", text: "13.00-16:00" },
          { value: "16:00:00", text: "16.00-18:00" }
        ];
      }
    }
  },
  created() {}
};
</script>

<style></style>
